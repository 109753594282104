
































































































































































































































































































































import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import City from "@/models/City";
import store from "@/store/index";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import { AxiosError } from "axios";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { Lead } from "@/models/Lead";
import Worker from "@/models/Worker";
import { ErrorCRM } from "@/models/Errors";

export default Vue.extend({
  components: { Errors, MaDatePicker },
  name: "formd-demographics",
  data() {
    return {
      formValid: false,

      birthDateMenu: false,
      doctor: null,
      rules: {
        required: rules.required,
        email: rules.email,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      stateCities: [],
      loadingCities: false,
      state: 0,
      gender: "male",
      loading: false,
      showErrors: false,
      errorMessage: "",

      /*  how it finded us
      how do i find us */

      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      loadingmedical: false,
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",
        mailingCity: -1,
        mailingAddress: "",
        mailingZip: "",
        birthDate: "",
        medicalLocation: "",
        homePhone: "",
        preferLanguage: "ENGLISH",
        isMale: true,
        howKnow: "",
        emergencyContact: "",
        emergencyPhone: "",
        emergencyRelation: "",
        email: "",
        weight: 0,
        height: 0,
        heightInches: 0,
        leadUuid: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getSellerN", "getpatientN"]),
    ...mapState("crmMedicFormModule", [
      "states",
      "loadingStates",
      "medicalLocations",
    ]),

    ...mapState("crmConfigurationsModule", ["leadSelect"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState(["profile"]),
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  async mounted() {
    this.loadingmedical = true;
    this.gender = "male";

    if (this.leadSelect != null) {
      this.form.firstName = (this.leadSelect as Lead).name;
      this.form.birthDate = (this.leadSelect as any).birthDate;
      this.form.weight = (this.leadSelect as any).weight
        ? (this.leadSelect as any).weight
        : 0;
      if ((this.leadSelect as any).height.toString().includes(".")) {
        this.form.height = Number(
          (this.leadSelect as any).height.toString().split(".")[0]
        );
        this.form.heightInches = Number(
          (this.leadSelect as any).height.toString().split(".")[1]
        );
      } else if ((this.leadSelect as any).height.toString().includes(",")) {
        this.form.height = Number(
          (this.leadSelect as any).height.toString().split(",")[0]
        );
        this.form.heightInches = Number(
          (this.leadSelect as any).height.toString().split(",")[1]
        );
      } else {
        this.form.height = (this.leadSelect as any).height;
      }
      this.form.lastName = (this.leadSelect as Lead).last_name;
      this.form.email = (this.leadSelect as Lead).email;
      this.form.isMale = (this.leadSelect as any).isMale;
      this.gender = (this.leadSelect as any).isMale ? "male" : "female";
      this.form.howKnow = this.leadSelect.howKnow;
      this.form.homePhone = (this.leadSelect as Lead).phone.replace("+1", "");
    }
    await this.actListStates();

    this.state = 23;

    await this.actGetMedicalLocations();
    this.form.medicalLocation =
      this.medicalLocations.length != 0 ? this.medicalLocations[0].uuid : "";
    this.loadingmedical = false;
    this.actListReferences();
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",
      "actGetMedicalLocations",
      "actGetEducationLevels",
    ]),

    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    create(): void {
      let { form: body } = this;
      let { birthDate, ...rest } = body;
      let ISObirdDate = new Date(birthDate).toISOString();
      if (this.leadSelect != null) {
        rest.leadUuid = this.leadSelect.uuid;
      }
      if (rest.homePhone) {
        rest.homePhone = "+1" + rest.homePhone;
      }
      if (rest.emergencyPhone) {
        rest.emergencyPhone = "+1" + rest.emergencyPhone;
      }

      if (rest.mailingAddress == "") {
        delete (rest as any).mailingAddress;
      }
      if (rest.mailingCity == -1) {
        delete (rest as any).mailingCity;
      }
      if (rest.mailingZip == "" || rest.mailingZip == null) {
        delete (rest as any).mailingZip;
      }

      if (
        rest.leadUuid == null ||
        rest.leadUuid == undefined ||
        rest.leadUuid == ""
      ) {
        delete (rest as any).leadUuid;
      }
      if (rest.emergencyPhone == "" || rest.emergencyPhone == null) {
        delete (rest as any).emergencyPhone;
      }

      this.loading = true;

      getAPI
        .post("/patient/create", { ...rest, birthDate: ISObirdDate })
        .then((res) => {
          notifySuccess(this.$t("medicForm.notificationPatientCreated"));
          this.showErrors = false;
          (this as any).mutleadSelect(null);
          this.$refs.patientForm.reset();
          this.loading = false;
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((error: any) => {
          this.loading = false;
          const errorResponse: ErrorCRM = error.response.data;
          let message = "Error: ";
          errorResponse.message.forEach((m) => {
            message = message + m + ", ";
          });
          message = message.substr(0, message.length - 1);
          notifyError(message);
        });
    },

    reset(): void {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.$refs.patientForm.reset();
      this.loading = false;
      this.$router.push({ name: "Patient list" });
    },
  },
  destroyed() {
    (this as any).mutleadSelect(null);
  },
});
